var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "editForm",
          staticClass: "edit-form search_form",
          attrs: {
            model: _vm.editForm,
            "label-position": "right",
            "label-width": "150px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "手机号 :", prop: "name", required: "" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入名称" },
                model: {
                  value: _vm.editForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "name", $$v)
                  },
                  expression: "editForm.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "会员等级：", prop: "number", required: "" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入设备ID" },
                model: {
                  value: _vm.editForm.number,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "number", $$v)
                  },
                  expression: "editForm.number"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "会员等级：", prop: "number", required: "" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入设备ID" },
                model: {
                  value: _vm.editForm.number,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "number", $$v)
                  },
                  expression: "editForm.number"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "来源：", prop: "number", required: "" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入设备ID" },
                model: {
                  value: _vm.editForm.number,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "number", $$v)
                  },
                  expression: "editForm.number"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "注册时间：", prop: "number", required: "" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入设备ID" },
                model: {
                  value: _vm.editForm.number,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "number", $$v)
                  },
                  expression: "editForm.number"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "关联卡号：", prop: "number", required: "" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入设备ID" },
                model: {
                  value: _vm.editForm.number,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "number", $$v)
                  },
                  expression: "editForm.number"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "footer-container",
          staticStyle: { "margin-top": "20px" }
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.selectPointBtn }
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "medium" },
              on: {
                click: function($event) {
                  _vm.pointDialog = false
                }
              }
            },
            [_vm._v("取消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }