//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { addEqu, updateEqu } from '@/api/charge';
import '@/utils/global.js';
import { portList } from '@/utils/util';
export default {
  name: 'edit',
  data: function data() {
    var self = this;
    return {
      // 查询字段
      editForm: {
        name: '',
        number: undefined,
        type: '',
        pricing_method: ''
      },
      lineStatus: _global.lineStatus,
      chargeType: _global.chargeType,
      billMode: _global.billMode,
      connectMode: _global.connectMode,
      portList: portList(),
      btnLoading: false
    };
  },
  created: function created() {// this.getTableData()
  },
  methods: {
    handleAddSubmit: function handleAddSubmit() {
      console.log('添加设备');
    },
    handleEditSubmit: function handleEditSubmit() {
      console.log('修改设备');
    },
    handleSearch: function handleSearch() {
      this.getTableData();
    },
    handleVillageList: function handleVillageList() {},
    selectPointBtn: function selectPointBtn() {
      console.log('dialog btn 提交');
    }
  }
};